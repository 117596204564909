import { register } from '@netcentric/component-loader';
import { isOnDesktop, getCookie } from '../../../commons/utils';
import config from './batcom-linklist.config';

class Linklist {
  constructor(el, params) {
    this.el = el;
    this.config = { ...config, ...params };
    this.selectors = config.selectors;
    this.classes = config.classes;
    this.init();
  }

  init() {
    if (this.el.classList.contains(this.classes.batcomSideNav)) {
      this.setRefs();
      this.toggleSideNavigation();
      this.addEventListeners();
    }
  }

  setRefs() {
    this.linkListTitle = this.el.querySelector(this.selectors.linkListTitle);
    this.linkListItemsWrapper = this.el.querySelector(this.selectors.linkListWrapper);
    this.linkListWrapperVisible = this.classes.linkListWrapperVisible;
    this.columnControlColumn = document.querySelectorAll(this.selectors.columnControlColumn);
    this.sideNavigation = document.querySelector(this.selectors.sideNavigation);
    this.logoutLink = this.el.querySelector(this.selectors.logoutLink);
  }

  toggleSideNavigation() {
    let wasNotDesktop = !isOnDesktop();
    let toggleVisibility = null;

    const attachEventListener = () => {
      if (!this.linkListTitle || toggleVisibility) return;
      toggleVisibility = () => {
        this.linkListItemsWrapper.classList.toggle(this.linkListWrapperVisible);
      };
      this.linkListTitle.addEventListener('click', toggleVisibility);
    };

    const detachEventListener = () => {
      if (!this.linkListTitle || !toggleVisibility) return;
      this.linkListTitle.removeEventListener('click', toggleVisibility);
      toggleVisibility = null;
    };

    if (wasNotDesktop) {
      attachEventListener();
    }

    const resizeObserver = new ResizeObserver(() => {
      const currentlyNotDesktop = !isOnDesktop();
      if (currentlyNotDesktop && !wasNotDesktop) {
        attachEventListener();
      } else if (!currentlyNotDesktop && wasNotDesktop) {
        detachEventListener();
      }
      wasNotDesktop = currentlyNotDesktop;
    });

    resizeObserver.observe(document.body);
  }

  async revokeTokenSession() {
    const headers = new Headers();
    headers.append('Ocp-Apim-Subscription-Key', `${this.config.bffAccessToken}`);
    headers.append('Market', `${this.config.market}`);
    headers.append('Brand', `${this.config.brand}`);
    headers.append('Content-Type', 'application/json');

    const userSessionToken = getCookie(this.config.authCookieName);

    headers.append('UserSessionToken', userSessionToken);

    const requestOptions = {
      method: 'POST',
      headers,
      body: JSON.stringify({ query: this.config.logoutCustomerMutation }),
    };

    try {
      const response = await fetch(this.config.identityServiceApiUrl, requestOptions);
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error(`Error occurred when signing out: ${error.message}`);
    }
  }

  dispatchLogoutErrorEvent(description) {
    const event = new CustomEvent('logout', {
      detail: {
        level: 'error',
        title: 'Logout',
        description,
      },
    });
    document.dispatchEvent(event);
  }

  async logoutCustomer() {
    if (this.logoutLink.classList.contains('disabled')) {
      return;
    }
    this.logoutLink.classList.add('disabled');
    try {
      const data = await this.revokeTokenSession();
      if (data?.data?.revokeCustomerTokenSession?.result === 'true') {
        window.location.href = this.config.successLogoutRedirectPage;
      } else {
        const errorMsg = data?.errors?.[0]?.message || 'Error occurred when signing out';
        throw new Error(errorMsg);
      }
    } catch (error) {
      this.dispatchLogoutErrorEvent(error.message);
    } finally {
      this.logoutLink.classList.remove('disabled');
    }
  }

  addEventListeners() {
    if (this.logoutLink) {
      this.logoutLink.addEventListener('click', (e) => {
        e.preventDefault();
        this.logoutCustomer();
      });
    }
  }
}

const getLogoutParams = (el) => {
  const logoutEl = el.querySelector('[data-logout-params]');
  const dataset = logoutEl ? logoutEl.dataset.logoutParams : '{}';
  try {
    return JSON.parse(dataset);
  } catch (error) {
    return {};
  }
};
const el = document.querySelector('.batcom-linklist');

const linkList = new Linklist(el, getLogoutParams(el));
register({ linkList });
